import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "@omnigenbiodata/react";
import App from "./App";
import store from "./store";
import { setupAmplify } from "./core/utils/amplify";
import { setupI18next } from "./core/utils/i18next";
import reportWebVitals from "./reportWebVitals";

setupAmplify();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={setupI18next()}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
