import { createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "../../core/api/user/userApi";
import {
  GetMyStaffProfileResponse,
  GetMyStaffProfileRejection,
  AcceptStaffLegalNoticesInputs,
} from "../../core/types/user.types";

// Thunks
export const getMyStaffProfileThunk = createAsyncThunk<
  GetMyStaffProfileResponse,
  undefined,
  GetMyStaffProfileRejection
>("user/getMyStaffProfile", async (values, { rejectWithValue }) => {
  try {
    const response = await userApi.getMyStaffProfile();
    if (response.data?.getMyProfile !== undefined) {
      return response.data.getMyProfile;
    }
    return rejectWithValue(response.errors);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const acceptStaffLegalNoticesThunk = createAsyncThunk<
  GetMyStaffProfileResponse,
  AcceptStaffLegalNoticesInputs,
  GetMyStaffProfileRejection
>("user/acceptStaffLegalNotices", async (values, { rejectWithValue }) => {
  try {
    const response = await userApi.acceptStaffLegalNotices(values);
    if (response.data?.acceptStaffLegalNotices !== undefined) {
      return response.data.acceptStaffLegalNotices;
    }
    return rejectWithValue(response.errors);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
