import { API } from "aws-amplify";
// import { API } from "../mock/aws-amplify";
import {
  MonitoringResponse,
  ChartInputs,
} from "../../../core/types/monitoring.types";
import {
  reportAgeGroupDistributionQuery,
  reportEnrolmentsByDeptQuery,
  reportEnrolmentsBySexQuery,
  reportEnrolmentsBySignatureMethodQuery,
  reportEnrolmentsByPopulationGroupQuery,
  studySummaryQuery,
  reportEnrolmentsByEHROptInQuery,
  reportDailyEnrolmentsWithdrawalsQuery,
} from "../../graphql/queries";

import chartDataMapper from "./mappings";

const queries: any = {
  enrolment: reportDailyEnrolmentsWithdrawalsQuery,
  withdrawal: reportDailyEnrolmentsWithdrawalsQuery,
  age: reportAgeGroupDistributionQuery,
  department: reportEnrolmentsByDeptQuery,
  sex: reportEnrolmentsBySexQuery,
  populationGroup: reportEnrolmentsByPopulationGroupQuery,
  signatures: reportEnrolmentsBySignatureMethodQuery,
  ehr: reportEnrolmentsByEHROptInQuery,
};

const monitoringApi = {
  fetchChart: async ({
    chartType,
    dateSelection,
    numDepts,
  }: ChartInputs): Promise<MonitoringResponse | any> => {
    const response = (await API.graphql({
      query: queries[chartType],
      variables: { dateSelection, numDepts },
    })) as MonitoringResponse;
    if (response.data) {
      return chartDataMapper(
        chartType,
        dateSelection,
        Object.values(response.data).pop()
      );
    }
    return response.errors;
  },
  fetchSummary: async (): Promise<MonitoringResponse | any> => {
    const response = (await API.graphql({
      query: studySummaryQuery,
    })) as MonitoringResponse;
    return response.data?.reportHeadlineTotals || response.errors;
  },
};

export default monitoringApi;
