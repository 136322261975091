export const getParticipantByHospitalNoQuery = `
query getParticipantByHospitalNoQuery($hospitalNo: String!) {
    getParticipantByHospitalNo(hospitalNo: $hospitalNo) {
        id
        hospitalNo
        email
        firstName
        surname
        dob
        postcode
        mobile
        landline
        languagePreference
        sex
        address
        notifyNewProducts
        notifyNewStudies
        termsAccepted
        privacyAccepted
        healthDataOptIn
        departmentOfEnrolment
        eligibility1
        eligibility2
        enrolmentVideo
        populationGroup
        selfDefinedEthnicity
        pis
        consent1
        consent2
        consent3
        consent4
        consent5
        consent6
        consent7
        consent8
        health
        staffMemberFirstName
        staffMemberSurname
        witnessFirstName
        witnessSurname
        enrolmentTime
        serumStatus
        serumSampleID
        wholeBloodStatus
        wholeBloodSampleID
        plasmaStatus
        plasmaSampleID
        serumCollectionTime
        wholeBloodCollectionTime
        plasmaCollectionTime
        withdrawalOption
        withdrawalReason
    }
}
`;

export const studyChartQuery = `
query studyChart($input: studyChartInput!) {
    studyChart(input: $input) {
        data
        chart
    }
}
`;

export const getMyStaffProfileQuery = `
query getMyProfileQuery {
    getMyProfile{
        id
	  	email
        firstName
		surname
        mobile
        privacyAccepted
        termsAccepted
    }
}
`;

export const getParticipantConsentFormLinkQuery = `
query getParticipantConsentFormLinkQuery($participantId: ID!) {
    getParticipantConsentFormLink(participantId: $participantId)
}`;

export const reportDailyEnrolmentsWithdrawalsQuery = `
query reportDailyEnrolmentsWithdrawalsQuery($dateSelection: DateSelectionInput) {
    reportDailyEnrolmentsWithdrawals(dateSelection: $dateSelection) {
        dt
        numEnrolments
        numWithdrawals
        sex
      }
    
}`;

export const reportAgeGroupDistributionQuery = `
query reportAgeGroupDistributionQuery($dateSelection: DateSelectionInput) {
    reportAgeGroupDistribution(dateSelection: $dateSelection) {
        ageCategory
        numEnrolments
        sex
      }
}`;

export const reportEnrolmentsByDeptQuery = `
query reportEnrolmentsByDeptQuery($dateSelection: DateSelectionInput, $numDepts: Int) {
    reportEnrolmentsByDept(dateSelection: $dateSelection, numDepts: $numDepts) {
        departmentOfEnrolment
        numEnrolments
        sex
      }
}`;

export const reportEnrolmentsBySexQuery = `
query reportEnrolmentsBySexQuery($dateSelection: DateSelectionInput) {
    reportEnrolmentsBySex(dateSelection: $dateSelection) {
        numEnrolments
        sex
      }
}`;

export const reportEnrolmentsByPopulationGroupQuery = `
query reportEnrolmentsByPopulationGroupQuery($dateSelection: DateSelectionInput) {
    reportEnrolmentsByPopulationGroup(dateSelection: $dateSelection) {
        numEnrolments
        populationGroup
      }
}`;

export const reportEnrolmentsBySignatureMethodQuery = `
query reportEnrolmentsBySignatureMethodQuery($dateSelection: DateSelectionInput) {
    reportEnrolmentsBySignatureMethod(dateSelection: $dateSelection) {
        numEnrolments
        signatureMethod
      }
}`;

export const reportEnrolmentsByEHROptInQuery = `
query reportEnrolmentsByEHROptInQuery($dateSelection: DateSelectionInput) {
    reportEnrolmentsByEHROptIn(dateSelection: $dateSelection) {
        numEnrolments
        EHROptIn
      }
}`;

export const studySummaryQuery = `
query reportHeadlineTotals {
    reportHeadlineTotals {
        distinctDepts
        totalEnrolments
        totalWithdrawals
    }
}`;
