import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import clsx from "classnames";

export interface TableColum {
  dataKey: string;
  name?: string;
  align?: "left" | "right" | "inherit" | "center" | "justify";
}

export interface TableDataProps {
  data: any[];
  columns: TableColum[];
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  headerRow: { background: theme.palette?.grey[200] },
  oddRow: { background: theme.palette?.grey[100] },
  evenRow: { background: theme.palette.grey[50] },
}));

function TableData({ title, data, columns }: TableDataProps) {
  const classes = useStyles();
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label={title}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {columns.map((col) => (
                <TableCell
                  key={`${title}-header-${col.name}`}
                  align={col.align || "right"}
                >
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={`${title}-${row.name}-${rowIndex}`}
                className={clsx({
                  [classes.oddRow]: rowIndex % 2 !== 0,
                  [classes.evenRow]: rowIndex % 2 === 0,
                })}
              >
                {columns.map((col) => (
                  <TableCell
                    key={`${title}-body-${col.name}-${
                      row[col.dataKey]
                    }-${rowIndex}`}
                    align={col.align || "right"}
                  >
                    {row[col.dataKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableData;
