import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useHistory } from "react-router-dom";
import { Loader } from "@omnigenbiodata/react";
import Markdown from "react-markdown";
import MainLayout from "../../../../layout/Main";
import CONTENT from "../../../../core/constants/content.constant";
import ROUTES from "../../../../core/constants/routes.constant";
import {
  acceptStaffLegalNoticesThunk,
  isBusySelector,
  userProfileSelector,
} from "../../../../store/user";
import { useAppDispatch, useAppSelector } from "../../../../store";

function PrivacyScene() {
  const { t } = useTranslation("privacy");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const profile = useAppSelector(userProfileSelector);
  const isBusy = useAppSelector(isBusySelector);

  useEffect(() => {
    if (
      profile &&
      profile.termsAccepted === CONTENT.CURRENT_TERMS_VERSION &&
      profile.privacyAccepted === CONTENT.CURRENT_PRIVACY_VERSION
    ) {
      history.push(ROUTES.portal);
    }
  }, [profile, history]);

  const formik = useFormik({
    initialValues: { agree: false },
    onSubmit: () => {
      dispatch(
        acceptStaffLegalNoticesThunk({
          termsAccepted: CONTENT.CURRENT_TERMS_VERSION,
          privacyAccepted: CONTENT.CURRENT_PRIVACY_VERSION,
        })
      );
    },
  });

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t("title")}
          </Typography>

          {[...Array(7)].map((x, i) => (
            <Box mb={2} key={`privacy-${i}`}>
              <Paper variant="outlined">
                <Box p={2}>
                  {t(`section${i + 1}.title`) && (
                    <Typography variant="h6" component="h2" paragraph>
                      {t(`section${i + 1}.title`)}
                    </Typography>
                  )}
                  <Typography variant="body2" component="span">
                    <Markdown>
                      {t(`section${i + 1}.content`, {
                        webAddress: CONTENT.MICROSITE_URL,
                        webAddressFriendly: CONTENT.MICROSITE_URL_FRIENDLY,
                        studyEmail: CONTENT.STUDY_EMAIL,
                        studyTelephone: CONTENT.STUDY_TEL,
                        termsEmail: CONTENT.TERMS_EMAIL,
                        dpoEmail: CONTENT.DPO_EMAIL,
                      })}
                    </Markdown>
                  </Typography>
                </Box>
              </Paper>
            </Box>
          ))}
          <Box mb={8}>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {t("button")}
            </Button>
          </Box>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Typography variant="body1">{t("help1.title")}</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ flexDirection: "column" }}
              id="panel-content"
            >
              <Typography variant="body1" component="span">
                <Markdown>{t("help1.content")}</Markdown>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </form>
      </MainLayout>
      <Loader label="Submitting" isVisible={isBusy} />
    </>
  );
}

export default PrivacyScene;
