import moment from "moment";
import { DateSelectionInputs } from "../../../types/monitoring.types";

const dateRange = (dateselection: DateSelectionInputs) => {
  var startOfWeek = moment(dateselection.fromDate);
  var endOfWeek = moment(dateselection.toDate);

  var days = [];
  var day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.format("YYYY-MM-DD"));
    day = day.clone().add(1, "d");
  }

  return days;
};

export const withdrawalMapper = (
  dateSelection: DateSelectionInputs,
  data: any
) => {
  const dayRange = dateRange(dateSelection);

  const result = data.reduce((prev: any, row: any) => {
    return {
      ...prev,
      [row.dt]: {
        ...prev[row.dt],
        [row.sex]: row.numWithdrawals,
        dt: row.dt,
      },
    };
  }, {});
  const fullRange = dayRange.map((day) => {
    return {
      dt: moment(day).format("DD MMM"),
      Male: result[day]?.Male || 0,
      Female: result[day]?.Female || 0,
      Intersex: result[day]?.Intersex || 0,
    };
  });
  return fullRange as any;
};
