import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

export interface ProfileSummaryProps {
  firstName?: string | null;
  lastName?: string | null;
  status?: string | null;
  statusColor?: "primary" | "secondary";
  sub?: string | null;
}

function ProfileSummary({
  firstName = null,
  lastName = null,
  status = null,
  statusColor = "primary",
  sub = null,
}: ProfileSummaryProps) {
  return (
    <div style={{ display: "flex", msFlexDirection: "row" }}>
      <Avatar
        style={{
          width: 100,
          height: 100,
          fontSize: 50,
          marginRight: 10,
        }}
      >
        {firstName?.substring(0, 1)}
        {lastName?.substring(0, 1)}
      </Avatar>
      <div>
        {status && (
          <Chip
            color={statusColor}
            label={status}
            style={{
              marginBottom: 5,
              textTransform: "uppercase",
            }}
          />
        )}
        {firstName && lastName && (
          <Typography variant="h4" component="p" color="textSecondary">
            {firstName} {lastName}
          </Typography>
        )}
        {sub && (
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            color="textSecondary"
          >
            {sub}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ProfileSummary;
