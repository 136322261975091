import React from "react";
import abbreviate from "number-abbreviate";

interface NumberFormatProps {
  number: string | number;
}

function NumberFormat({ number }: NumberFormatProps) {
  return <>{abbreviate(number, 1)}</>;
}

export default NumberFormat;
