import { createSlice } from "@reduxjs/toolkit";
import { MyStaffProfile } from "../../core/types/user.types";
import {
  KeyValues,
  StoreStatus,
  GraphQlError,
} from "../../core/types/common.types";
import { getMyStaffProfileThunk, acceptStaffLegalNoticesThunk } from "./thunks";

// Initial State
export interface UserState {
  errors: GraphQlError[] | null;
  result: MyStaffProfile | null;
  setupRequired: boolean;
  status: StoreStatus;
  values: KeyValues | null;
}

export const initialState: UserState = {
  errors: null,
  result: null,
  setupRequired: false,
  status: StoreStatus.IDLE,
  values: null,
};

// Slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserStore: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [getMyStaffProfileThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [getMyStaffProfileThunk.fulfilled.type]: (state, action) => {
      state.errors = null;
      state.result = action.payload;
      state.setupRequired = action.payload ? false : true;
      state.status = StoreStatus.IDLE;
    },
    [getMyStaffProfileThunk.rejected.type]: (state, action) => {
      state.errors = action.payload;
      state.result = null;
      state.status = StoreStatus.ERROR;
    },
    [acceptStaffLegalNoticesThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [acceptStaffLegalNoticesThunk.fulfilled.type]: (state, action) => {
      state.errors = null;
      state.result = action.payload;
      state.setupRequired = false;
      state.status = StoreStatus.IDLE;
    },
    [acceptStaffLegalNoticesThunk.rejected.type]: (state, action) => {
      state.errors = action.payload;
      state.status = StoreStatus.ERROR;
    },
  },
});

// Action Creators
export * from "./thunks";
export * from "./selectors";
export const { resetUserStore } = userSlice.actions;
export default userSlice.reducer;
