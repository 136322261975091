import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chartContainer: {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  chartTitle: { flexGrow: 1 },
  chartLegend: { paddingTop: 20 },
}));
