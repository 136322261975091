import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import Box from "@material-ui/core/Box";
import { Link, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../../../../../store";
import {
  updateParticipantProfileThunk,
  participantProfileSelector,
} from "../../../../../../../../store/participant";
import {
  getParticipantConsentFormLinkThunk,
  resultSelector,
  isBusySelector as isConsentBusySelector,
  hasErrorSelector as hasConsentLinkErrorSelector,
} from "../../../../../../../../store/consent";

import { HOSPTIAL_NUM_PREFIX } from "../../../../../../../../core/constants/forms.constant";
import ROUTES from "../../../../../../../../core/constants/routes.constant";
import InnerLayout from "../../../../../../../../layout/Inner";
import Heading1 from "../../../../../../../../components/text/Heading1";
import ProfileSummary from "../../components/ProfileSummary";
import Tabs from "./components/TabWrapper";
import ProfileTab from "./components/ProfileTab";
import SamplesTab from "./components/SamplesTab";
import EnrolmentTab from "./components/EnrolmentTab";

function ParticipantsResultScene() {
  const { t } = useTranslation("portal");
  const [hasInitiatedDownload, setHasInitiatedDownload] = useState(false);
  const dispatch = useAppDispatch();
  let { hospitalNo } = useParams<any>();
  const profile = useAppSelector(participantProfileSelector);
  const signedPDFUrl = useAppSelector(resultSelector);
  const signedPDFIsBusy = useAppSelector(isConsentBusySelector);
  const hasConsentLinkError = useAppSelector(hasConsentLinkErrorSelector);

  useEffect(() => {
    if (signedPDFUrl && hasInitiatedDownload) {
      window.open(signedPDFUrl, "_blank");
    }
  }, [signedPDFUrl, hasInitiatedDownload]);

  const handleSaveProfile = (values: any) => {
    dispatch(updateParticipantProfileThunk(values));
  };

  const handleConsentLinkRequest = () => {
    if (profile) {
      setHasInitiatedDownload(true);
      dispatch(
        getParticipantConsentFormLinkThunk({ participantId: profile?.id })
      );
    }
  };

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        {profile && (
          <>
            <Heading1 icon={GroupIcon}>
              <Link
                to={ROUTES.participantLookup}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {t("participant.title")}
              </Link>{" "}
              &gt;{" "}
              <span style={{ fontSize: "0.7em" }}>
                {HOSPTIAL_NUM_PREFIX}
                {hospitalNo.toString()}
              </span>
            </Heading1>
            <Box mb={4}>
              <ProfileSummary
                firstName={profile.firstName}
                lastName={profile.surname}
                sub={`${HOSPTIAL_NUM_PREFIX}${hospitalNo}`}
                status={t(`${profile.withdrawalOption || "Default"}.status`, {
                  ns: "withdrawals",
                })}
                statusColor={
                  !profile.withdrawalOption ? "primary" : "secondary"
                }
              />
            </Box>
            <Tabs>
              <ProfileTab
                profile={profile}
                onSave={handleSaveProfile}
                onSaveEmail={() => undefined}
                onSaveMobile={() => undefined}
              />
              <EnrolmentTab
                profile={profile}
                onConsentLink={handleConsentLinkRequest}
                isConsentLinkBusy={signedPDFIsBusy}
                hasConsentLinkError={
                  hasConsentLinkError && hasInitiatedDownload
                }
              />
              <SamplesTab profile={profile} />
            </Tabs>
          </>
        )}
      </InnerLayout>
    </>
  );
}

export default ParticipantsResultScene;
