import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { BsFilePdf } from "react-icons/bs";
import Heading2 from "../../../../../../../../../../../components/text/Heading2";
import { useTranslation } from "react-i18next";

export interface ConsentFormBlockProps {
  onConsentLink: (event: any) => void;
  isConsentLinkBusy: boolean;
  hasConsentLinkError: boolean;
}

function ConsentFormBlock({
  onConsentLink,
  isConsentLinkBusy,
  hasConsentLinkError,
}: ConsentFormBlockProps) {
  const { t } = useTranslation("portal");
  return (
    <Paper>
      <Box p={4}>
        <Heading2 color="textPrimary" icon={BsFilePdf}>
          {t("participantConsent.title")}
        </Heading2>
        <Typography variant="body1" component="p" paragraph>
          {t("participantConsent.para1")}
        </Typography>
        <Button
          variant="outlined"
          color="default"
          size="small"
          onClick={onConsentLink}
          disabled={isConsentLinkBusy}
          data-testid="downloadPdf"
        >
          <div>
            {!isConsentLinkBusy && t("participantConsent.button")}
            {isConsentLinkBusy && t("participantConsent.busy")}
            {!isConsentLinkBusy && hasConsentLinkError && (
              <Typography variant="body2" color="error">
                {t("participantConsent.error")}
              </Typography>
            )}
          </div>
        </Button>
      </Box>
    </Paper>
  );
}

export default ConsentFormBlock;
