import i18next from "i18next";
import portal_en from "../../../translations/en/portal.json";
import forms_en from "../../../translations/en/forms.json";
import values_en from "../../../translations/en/values.json";
import terms_en from "../../../translations/en/terms.json";
import privacy_en from "../../../translations/en/privacy.json";
import validation_en from "../../../translations/en/validation.json";
import withdrawals_en from "../../../translations/en/withdrawals.json";

export const setupI18next = (defaultLanguage = "en") => {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: defaultLanguage, // language to use
    resources: {
      en: {
        forms: forms_en,
        values: values_en,
        portal: portal_en,
        terms: terms_en,
        privacy: privacy_en,
        validation: validation_en,
        withdrawals: withdrawals_en,
      },
    },
  });
  return i18next;
};
