import {
  BloodSampleType,
  Department,
  Gender,
  LanguageCode,
} from "./common.types";

export enum WithdrawalOption {
  COMPLETE = "Complete",
  SHARING_ONLY = "SharingOnly",
  COLLECTION_ONLY = "CollectionOnly",
  SHARING_AND_COLLECTION = "SharingAndCollection",
}

export interface ParticipantProfile {
  id: string;
  hospitalNo: string;
  email: string | null;
  firstName: string | null;
  surname: string | null;
  dob: string | null;
  postcode: string | null;
  mobile: string | null;
  landline: string | null;
  languagePreference: LanguageCode;
  sex: Gender;
  address: string | null;
  notifyNewProducts: boolean;
  notifyNewStudies: boolean;
  termsAccepted: string | null;
  privacyAccepted: string | null;
  healthDataOptIn: boolean;
  departmentOfEnrolment: Department;
  eligibility1: boolean;
  eligibility2: boolean;
  enrolmentVideo: boolean;
  populationGroup: string;
  selfDefinedEthnicity: string;
  pis: boolean;
  consent1: boolean;
  consent2: boolean;
  consent3: boolean;
  consent4: boolean;
  consent5: boolean;
  consent6: boolean;
  consent7: boolean;
  consent8: boolean;
  health: boolean;
  staffMemberFirstName: string | null;
  staffMemberSurname: string | null;
  witnessFirstName?: string | null;
  witnessSurname?: string | null;
  enrolmentTime: string | null;
  serumStatus: BloodSampleType | null;
  serumSampleID: string | null;
  wholeBloodStatus: BloodSampleType | null;
  wholeBloodSampleID: string | null;
  plasmaStatus: BloodSampleType | null;
  plasmaSampleID: string | null;
  serumCollectionTime: string | null;
  wholeBloodCollectionTime: string | null;
  plasmaCollectionTime: string | null;
  withdrawalOption: WithdrawalOption | null;
  withdrawalReason: string | null;
}

/* Inputs */
export interface ParticipantProfileInput {
  id: string;
  firstName: string;
  surname: string;
  address: string;
  landline: string;
  postcode: string;
}

export interface GetParticipantConsentFormLinkInput {
  participantId: string;
}

export interface WithdrawParticipantInput {
  participantId: string;
  withdrawalReason?: string;
  withdrawalOption?: string;
}

/* Responses */
export interface ParticipantLookupResponse {
  data: { getParticipantByHospitalNo: ParticipantProfile };
}

export interface WithdrawParticipantResponse {
  data: { adminWithdrawParticipant: ParticipantProfile };
}

export interface UpdateParticipantProfileResponse {
  data: { updateParticipantProfile: ParticipantProfile };
}

export interface GetParticipantConsentFormLinkResponse {
  data: { getParticipantConsentFormLink: string };
}

/* Rejection */
export interface ParticipantRejection {
  rejectValue: string;
}

export interface ParticipantLookupRejection {
  rejectValue: string;
}

export interface UpdateParticipantProfileRejection {
  rejectValue: string;
}
