import React from "react";
import { Switch } from "react-router-dom";
import TermsScene from "./scenes/Terms";
import PrivacyScene from "./scenes/Privacy";
import { AuthRoute } from "@omnigenbiodata/react";
import { ROUTES } from "../../core/constants/routes.constant";

const ConsentScene = () => {
  return (
    <Switch>
      <AuthRoute exact path={ROUTES.consentPrivacy} component={PrivacyScene} />
      <AuthRoute path={ROUTES.consentTerms} component={TermsScene} />
    </Switch>
  );
};

export default ConsentScene;
