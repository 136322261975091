import { DateSelectionInputs } from "../../../types/monitoring.types";
import { enrolmentMapper } from "./enrolmentMapper";
import { ageGroupMapper } from "./ageGroupMapper";
import { departmentMapper } from "./departmentMapper";
import { withdrawalMapper } from "./withdrawalMapper";

export const chartDataMapper = (
  chartType: string,
  dateSelection: DateSelectionInputs,
  data: any
) => {
  switch (chartType) {
    case "enrolment":
      return enrolmentMapper(dateSelection, data);
    case "withdrawal":
      return withdrawalMapper(dateSelection, data);
    case "age":
      return ageGroupMapper(data);
    case "department":
      return departmentMapper(data);
    default:
      return data;
  }
};

export default chartDataMapper;
