import React, { useEffect } from "react";
import { Loader } from "@omnigenbiodata/react";
import { Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setupSelector,
  userProfileSelector,
  getMyStaffProfileThunk,
} from "../../store/user";
import ROUTES from "../../core/constants/routes.constant";
import CONTENT from "../../core/constants/content.constant";

function SplashScene() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(userProfileSelector);
  const setupRequired = useAppSelector(setupSelector);

  useEffect(() => {
    if (!profile && !setupRequired) {
      dispatch(getMyStaffProfileThunk());
    }
  }, [dispatch, profile, setupRequired]);

  if (!profile && !setupRequired) {
    return <Loader label="Loading Profile" isVisible />;
  }
  if (
    setupRequired ||
    profile?.termsAccepted !== CONTENT.CURRENT_TERMS_VERSION
  ) {
    return <Redirect to={ROUTES.consentTerms} />;
  }
  return <Redirect to={ROUTES.root} />;
}

export default SplashScene;
