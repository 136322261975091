import React from "react";
import { Route, Switch } from "react-router-dom";
import WithdrawalOption from "./scenes/WithdrawalOption";
import WithdrawalReason from "./scenes/WithdrawalReason";
import WithdrawalConfirm from "./scenes/WithdrawalConfirm";
import ROUTES from "../../../../../../../../core/constants/routes.constant";

function ParticipantWithdrawalScene() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.participantWithdrawal}
        component={WithdrawalOption}
      />
      <Route
        exact
        path={ROUTES.participantWithdrawalReason}
        component={WithdrawalReason}
      />
      <Route
        exact
        path={ROUTES.participantWithdrawalConfirm}
        component={WithdrawalConfirm}
      />
    </Switch>
  );
}

export default ParticipantWithdrawalScene;
