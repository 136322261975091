export const APPSYNC = {
  region: process.env.REACT_APP_APPSYNC_REGION,
  url: process.env.REACT_APP_APPSYNC_URL,
  authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,
};

export const COGNITO = {
  region: process.env.REACT_APP_COGNITO_REGION,
  user_pool_id: process.env.REACT_APP_STAFF_COGNITO_USER_POOL_ID,
  app_client_id: process.env.REACT_APP_SITE_PORTAL_COGNITO_APP_CLIENT_ID,
  identity_pool_id: process.env.REACT_APP_STAFF_COGNITO_IDENTITY_POOL_ID,
  auth_flow: process.env.REACT_APP_COGNITO_AUTH_FLOW,
};

export const S3 = {
  bucket: process.env.REACT_APP_FINGERPRINT_BUCKET_NAME,
  consentBucket: process.env.REACT_APP_CONSENT_BUCKET_NAME,
};

export const AWS = {
  region: COGNITO.region,
  aws_cognito_region: COGNITO.region,
  userPoolId: COGNITO.user_pool_id,
  identityPoolId: COGNITO.identity_pool_id,
  userPoolWebClientId: COGNITO.app_client_id,
  authenticationFlowType: COGNITO.auth_flow,
  aws_appsync_graphqlEndpoint: APPSYNC.url,
  aws_appsync_region: APPSYNC.region,
  aws_appsync_authenticationType: APPSYNC.authenticationType,
  Storage: {
    AWSS3: {
      bucket: S3.bucket,
      region: COGNITO.region,
    },
  },
};

export const AUTH_CHALLENGES = {
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  MFA_SETUP: "MFA_SETUP",
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  NEW_PASSWORD: "NEW_PASSWORD",
};

export default AWS;
