import React, { ElementType, ReactNode } from "react";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

export interface Heading1Props {
  children: ReactNode;
  menu?: ReactNode;
  icon?: ElementType | null;
}
const Heading1 = ({ icon = null, children, menu = null }: Heading1Props) => {
  let IconComponent: ElementType | null = icon;

  return (
    <Box mb={4}>
      <Toolbar variant="dense" style={{ padding: 0 }}>
        {icon && IconComponent && (
          <>
            <IconComponent
              fontSize="large"
              color="action"
              style={{ verticalAlign: "middle", marginRight: 10 }}
            />{" "}
          </>
        )}
        <Typography
          variant="h4"
          component="h1"
          color="textSecondary"
          style={{ flexGrow: 1 }}
        >
          {children}
        </Typography>
        {menu}
      </Toolbar>
    </Box>
  );
};

export default Heading1;
