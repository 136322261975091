import React from "react";
import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import Box from "@material-ui/core/Box";
import { ButtonRow, FormTextarea } from "@omnigenbiodata/react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store";
import { HOSPTIAL_NUM_PREFIX } from "../../../../../../../../../../core/constants/forms.constant";

import InnerLayout from "../../../../../../../../../../layout/Inner";
import Heading1 from "../../../../../../../../../../components/text/Heading1";

import Typography from "@material-ui/core/Typography";
import ROUTES, {
  URL_PARAMS,
} from "../../../../../../../../../../core/constants/routes.constant";
import ContentCard from "../../../../../../../../../../components/content/ContentCard";
import {
  withdrawalForward,
  isBusySelector,
  participantProfileSelector,
} from "../../../../../../../../../../store/participant";

function WithdrawalReasonScene() {
  const { t } = useTranslation("portal");
  let { hospitalNo } = useParams<any>();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(participantProfileSelector);
  const isBusy = useAppSelector(isBusySelector);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      withdrawalReason: "",
    },
    onSubmit: (values) => {
      dispatch(withdrawalForward(values));
      history.push(
        ROUTES.participantWithdrawalConfirm.replace(
          URL_PARAMS.hospitalNo,
          hospitalNo
        )
      );
    },
  });

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        {!isBusy && profile && (
          <>
            <Heading1 icon={GroupIcon}>
              {t("participant.title")} &gt;{" "}
              <span style={{ fontSize: "0.7em" }}>
                {HOSPTIAL_NUM_PREFIX}
                {hospitalNo.toString()}
              </span>
            </Heading1>

            <ContentCard>
              <form onSubmit={formik.handleSubmit}>
                <Box mb={8}>
                  <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    gutterBottom
                  >
                    {t("withdrawalReason.title")}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    align="center"
                    gutterBottom
                  >
                    <strong>{t("withdrawalReason.subtitle")}</strong>
                  </Typography>
                  <FormTextarea
                    label={t("withdrawalReason.subtitle")}
                    name="withdrawalReason"
                    error={formik.errors.withdrawalReason}
                    value={formik.values.withdrawalReason}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.withdrawalReason}
                  />
                </Box>
                <ButtonRow
                  backLabel={t("withdrawalReason.back")}
                  forwardLabel={t("withdrawalReason.button")}
                  forwardColor="secondary"
                  buttonSize="small"
                />
              </form>
            </ContentCard>
          </>
        )}
      </InnerLayout>
    </>
  );
}

export default WithdrawalReasonScene;
