import { API } from "aws-amplify";
// import { API } from "../mock/aws-amplify";
import { getMyStaffProfileQuery } from "../../graphql/queries";
import { acceptStaffLegalNoticesMutation } from "../../graphql/mutations";
import {
  AcceptStaffLegalNoticesInputs,
  GetMyStaffProfileResponse,
  AcceptStaffLegalNoticesResponse,
} from "../../types/user.types";

const userAPI = {
  getMyStaffProfile: async (): Promise<GetMyStaffProfileResponse | any> => {
    return (await API.graphql({
      query: getMyStaffProfileQuery,
    })) as GetMyStaffProfileResponse;
  },
  acceptStaffLegalNotices: async (
    values: AcceptStaffLegalNoticesInputs
  ): Promise<GetMyStaffProfileResponse | any> => {
    return (await API.graphql({
      query: acceptStaffLegalNoticesMutation,
      variables: values,
    })) as AcceptStaffLegalNoticesResponse;
  },
};

export default userAPI;
