import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { AuthRoute } from "@omnigenbiodata/react";
import HomeScene from "./scenes/Home";
import ParticipantsScene from "./scenes/Participants";
import MonitoringScene from "./scenes/Monitoring";
import ROUTES from "../../core/constants/routes.constant";
import { useAppSelector } from "../../store";
import { userProfileSelector } from "../../store/user";

function PoertalScene() {
  const profile = useAppSelector(userProfileSelector);

  if (!profile) {
    return <Redirect to={ROUTES.splash} />;
  }

  return (
    <Switch>
      <AuthRoute exact path={ROUTES.portal} component={HomeScene} />
      <AuthRoute
        path={ROUTES.participantLookup}
        component={ParticipantsScene}
      />
      <AuthRoute path={ROUTES.monitoring} component={MonitoringScene} />
    </Switch>
  );
}

export default PoertalScene;
