import React from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MainLayout from "../../../../layout/Main";
import ROUTES from "../../../../core/constants/routes.constant";
import CONTENT from "../../../../core/constants/content.constant";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

function TermsScene() {
  const { t } = useTranslation("terms");
  const history = useHistory();
  const formik = useFormik({
    initialValues: { agree: false },
    onSubmit: (values) => {
      history.push(ROUTES.consentPrivacy);
    },
  });

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {t("title")}
        </Typography>

        <Typography
          variant="body1"
          component="span"
          align="center"
          color="secondary"
        >
          <Markdown>{t("subtitle")}</Markdown>
        </Typography>

        {[...Array(23)].map((x, i) => (
          <Box mb={2} key={`term-${i}`}>
            <Paper variant="outlined">
              <Box p={2}>
                <Typography variant="h6" component="h2" paragraph>
                  {i + 1}. {t(`section${i + 1}.title`)}
                </Typography>
                <Typography variant="body2" component="span">
                  <Markdown>
                    {t(`section${i + 1}.content`, {
                      webAddress: CONTENT.MICROSITE_URL,
                      studyEmail: CONTENT.STUDY_EMAIL,
                      studyTelephone: CONTENT.STUDY_TEL,
                    })}
                  </Markdown>
                </Typography>
              </Box>
            </Paper>
          </Box>
        ))}
        <Box mb={8}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {t("button")}
          </Button>
        </Box>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography variant="body1">{t("help1.title")}</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ flexDirection: "column" }}
            id="panel-content"
          >
            <Typography variant="body1" component="span">
              <Markdown>{t("help1.content")}</Markdown>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </form>
    </MainLayout>
  );
}

export default TermsScene;
