import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Scenes from "./scenes";
import { AuthProvider, ScrollToTop } from "@omnigenbiodata/react";
import ROUTES from "./core/constants/routes.constant";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider
        authPath={ROUTES.auth}
        postLoginPath={ROUTES.splash}
        postLogoutPath={ROUTES.authSignOut}
      >
        <Scenes />
      </AuthProvider>
    </Router>
  );
}

export default App;
