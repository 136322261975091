import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 290,
  },
  icon: {
    marginRight: 10,
    fontSize: "1.4em",
    color: theme.palette.primary.main,
  },
}));
