import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { RiPhoneLockLine } from "react-icons/ri";
import Typography from "@material-ui/core/Typography";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { ButtonRow, FormInput, Loader, useAuth } from "@omnigenbiodata/react";
import MainLayout from "../../../../layout/Main";
import { AUTH_CHALLENGES } from "../../../../core/constants/aws.constant";

function MultiFactor() {
  const { t } = useTranslation("validation");
  const {
    hasError,
    isBusy,
    challengeName,
    user,
    setupCode,
    completeSetupMfa,
    completeMfa,
  } = useAuth();

  const validationSchema = yup.object().shape({
    otp: yup.string().required(t("otpRequired")),
  });

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema,
    onSubmit: (values: any) => {
      const { otp } = values;
      if (challengeName === AUTH_CHALLENGES.MFA_SETUP) {
        completeSetupMfa(otp);
      } else {
        completeMfa(otp);
      }
    },
  });

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Multi Factor Authentication
            </Typography>
            <Typography paragraph variant="body1" align="center">
              Please enter your one time password below.
            </Typography>
          </Box>
          {hasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Invalid</strong>
                </Typography>
                <Typography variant="body1">
                  The passcode you provided was incorrect.
                </Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.otp}
              name="otp"
              label="6-digit one time password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.otp}
              value={formik.values.otp}
              startAdornment={<RiPhoneLockLine />}
            />
          </Box>
          {setupCode && user && (
            <>
              <div style={{ textAlign: "center", marginBottom: 90 }}>
                <QRCode
                  value={
                    "otpauth://totp/DMSA_STAFF:" +
                    user.username +
                    "?secret=" +
                    setupCode +
                    "&issuer=DMSA_STAFF"
                  }
                />
              </div>
              <span id="setupCode" style={{ opacity: 0 }}>
                {setupCode}
              </span>
            </>
          )}
          <ButtonRow forwardLabel="Confirm" buttonSize="small" />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default MultiFactor;
