import { createSlice } from "@reduxjs/toolkit";
import { StoreStatus, KeyValues } from "../../core/types/common.types";
import { fetchSummaryThunk } from "./thunks";

export interface SummaryState {
  errors: any;
  result: any;
  status: StoreStatus;
  values: KeyValues | null;
}

// Initial State
export const initialState: SummaryState = {
  status: StoreStatus.IDLE,
  values: null,
  result: null,
  errors: null,
};

// Slice
export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    resetSummaryResult: (state: SummaryState) => ({
      ...state,
      status: initialState.status,
      errors: initialState.errors,
      result: initialState.result,
    }),
    resetSummary: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [fetchSummaryThunk.pending.type]: (state: SummaryState, action) => {
      state.status = StoreStatus.BUSY;
      state.errors = null;
    },
    [fetchSummaryThunk.fulfilled.type]: (state: SummaryState, action) => {
      state.result = action.payload;
      state.status = StoreStatus.IDLE;
    },
    [fetchSummaryThunk.rejected.type]: (state: SummaryState, action) => {
      state.result = null;
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
    },
  },
});

// Action Creators
export * from "./thunks";
export * from "./selectors";
export const { resetSummaryResult, resetSummary } = summarySlice.actions;
export default summarySlice.reducer;
