import { makeStyles, lighten } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperDanger: {
    background: lighten(theme.palette.secondary.light, 0.8),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: lighten(theme.palette.secondary.main, 0.2),
  },
}));
