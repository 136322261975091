import React from "react";
import { Route, Switch } from "react-router-dom";
import ParticipantLookup from "./scenes/ParticipantLookup";
import ParticipantResult from "./scenes/ParticipantResult";
import ROUTES from "../../../../core/constants/routes.constant";

function ParticipantsScene() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.participantLookup}
        component={ParticipantLookup}
      />
      <Route path={ROUTES.participant} component={ParticipantResult} />
    </Switch>
  );
}

export default ParticipantsScene;
