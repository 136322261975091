import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TuneIcon from "@material-ui/icons/Tune";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import MomentUtils from "@date-io/moment";
import * as yup from "yup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";

function ReportLookup({
  onFilter = () => undefined,
  initialValues = null,
}: any) {
  const validationSchema = yup.object().shape({
    fromDate: yup.string().required(),
    toDate: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onFilter(values);
    },
  });

  return (
    <Paper elevation={1}>
      <Box p={2}>
        {initialValues && (
          <form onSubmit={formik.handleSubmit}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container justifyContent="space-around" alignItems="center">
                <TuneIcon fontSize="large" color="action" />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date from"
                  error={formik.errors.fromDate ? true : false}
                  name="fromDate"
                  disableFuture
                  onChange={(val) => {
                    formik.setFieldValue("fromDate", val?.format("YYYY-MM-DD"));
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.fromDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ marginLeft: 10 }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date to"
                  disableFuture
                  error={formik.errors.toDate ? true : false}
                  name="toDate"
                  onChange={(val) => {
                    formik.setFieldValue("toDate", val?.format("YYYY-MM-DD"));
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.toDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ marginRight: "auto", marginLeft: 10 }}
                />

                <Button
                  color="default"
                  variant="outlined"
                  size="small"
                  type="submit"
                  endIcon={<ChevronRightOutlinedIcon />}
                >
                  Report
                </Button>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        )}
      </Box>
    </Paper>
  );
}

export default ReportLookup;
