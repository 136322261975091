import React from "react";
import Grid from "@material-ui/core/Grid";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import SummaryBlock from "../../../../../../components/content/StatSummary";

const SummaryStrip = ({
  isBusy,
  participantTotal,
  participantData,
  departmentTotal,
  departmentData,
  withdrawalTotal,
  withdrawalData,
}: any) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <SummaryBlock
          total={participantTotal}
          label="Enrolments"
          color="primary"
          sparkColor="green"
          sparkline={participantData}
          isBusy={isBusy}
          icon={
            <PeopleAltOutlinedIcon
              fontSize="large"
              color="primary"
              style={{ margin: "0 auto", display: "block" }}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SummaryBlock
          total={departmentTotal}
          label="Departments"
          color="textPrimary"
          sparkColor="black"
          sparkline={departmentData}
          isBusy={isBusy}
          icon={
            <LocalHospitalOutlinedIcon
              fontSize="large"
              color="action"
              style={{ margin: "0 auto", display: "block" }}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SummaryBlock
          total={withdrawalTotal}
          label="Withdrawals"
          color="secondary"
          sparkColor="#D99089"
          sparkline={withdrawalData}
          isBusy={isBusy}
          icon={
            <BlockOutlinedIcon
              fontSize="large"
              color="secondary"
              style={{ margin: "0 auto", display: "block" }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default SummaryStrip;
