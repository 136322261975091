import { API } from "aws-amplify";
// import { API } from "../mock/aws-amplify";
import {
  getParticipantByHospitalNoQuery,
  getParticipantConsentFormLinkQuery,
} from "../../graphql/queries";
import {
  adminWithdrawParticipantMutation,
  updateParticipantProfileMutation,
} from "../../graphql/mutations";
import {
  ParticipantLookupResponse,
  ParticipantProfileInput,
  UpdateParticipantProfileResponse,
  WithdrawParticipantInput,
  WithdrawParticipantResponse,
  GetParticipantConsentFormLinkInput,
} from "../../types/participants.types";

const participantsAPI = {
  getParticipantByHospitalNo: async (
    values: any
  ): Promise<ParticipantLookupResponse | any> => {
    return (await API.graphql({
      query: getParticipantByHospitalNoQuery,
      variables: {
        hospitalNo: values.hospitalNo,
      },
    })) as ParticipantLookupResponse;
  },
  updateParticipantProfile: async (
    values: ParticipantProfileInput
  ): Promise<UpdateParticipantProfileResponse | any> => {
    return (await API.graphql({
      query: updateParticipantProfileMutation,
      variables: { newProfile: values },
    })) as UpdateParticipantProfileResponse;
  },
  adminWithdrawParticipant: async (
    values: WithdrawParticipantInput
  ): Promise<WithdrawParticipantResponse | any> => {
    return (await API.graphql({
      query: adminWithdrawParticipantMutation,
      variables: values,
    })) as WithdrawParticipantResponse;
  },
  getParticipantConsentFormLink: async (
    values: GetParticipantConsentFormLinkInput
  ): Promise<WithdrawParticipantResponse | any> => {
    return (await API.graphql({
      query: getParticipantConsentFormLinkQuery,
      variables: values,
    })) as WithdrawParticipantResponse;
  },
};

export default participantsAPI;
