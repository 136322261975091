import React from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Markdown from "react-markdown";
import { useStyles } from "./component.styles";

export interface AccordionBlockProps {
  id: string;
  title: string;
  content: string;
}
const AccordionBlock = ({ id, title, content }: AccordionBlockProps) => {
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        <Typography variant="body1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        id={`panel-${id}-content`}
        className={classes.accordionContent}
      >
        {content && (
          <Typography variant="body1" component="span">
            <Markdown>{content}</Markdown>
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionBlock;
