import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";
import NumberFormat from "../NumberFormat";

interface TooltiItem {
  value: string;
}
interface CustomTooltipProps {
  active?: boolean;
  payload?: TooltiItem[];
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active) {
    return (
      <span>
        {payload?.map((item: TooltiItem, index: number) => (
          <p key={`tooltip-${index}-${item.value}`}>{item.value}</p>
        ))}
      </span>
    );
  } else {
    return null;
  }
};

const StatSummary = ({
  color,
  sparkColor,
  total,
  label,
  sparkline,
  isBusy,
  icon,
}: any) => {
  return (
    <Paper elevation={3}>
      <Box p={4}>
        {icon}
        {isBusy && (
          <>
            <Skeleton variant="text" height={100} />
            <Skeleton variant="text" height={90} />
          </>
        )}
        {!isBusy && total && (
          <Typography variant="h1" component="div" color={color} align="center">
            <strong>
              <NumberFormat number={total} />
            </strong>
          </Typography>
        )}
        {!isBusy && sparkline && (
          <div
            style={{
              height: 90,
              margin: "0 auto",
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={200} height={90} data={sparkline}>
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke={sparkColor}
                  strokeWidth={2}
                />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
        <Typography variant="h6" component="h3" align="center">
          <strong>{label}</strong>
        </Typography>
      </Box>
    </Paper>
  );
};

export default StatSummary;
