import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./scenes/Login";
import MultiFactor from "./scenes/MultiFactor";
import NewPassword from "./scenes/NewPassword";
import ResetPassword from "./scenes/ResetPassword";
import SubmitResetPassword from "./scenes/SubmitResetPassword";

import { useAuth } from "@omnigenbiodata/react";
import ROUTES from "../../core/constants/routes.constant";
import { AUTH_CHALLENGES } from "../../core/constants/aws.constant";
import Logout from "./scenes/Logout";

function Auth() {
  const { challengeName, resetStatus } = useAuth();
  const [resetCount, setResetCount] = useState(0);

  useEffect(() => {
    if (resetCount < 1) {
      resetStatus();
      setResetCount(resetCount + 1);
    }
  }, [resetStatus, resetCount]);

  if (
    challengeName === AUTH_CHALLENGES.MFA_SETUP ||
    challengeName === AUTH_CHALLENGES.SOFTWARE_TOKEN_MFA
  ) {
    return <MultiFactor />;
  }

  if (
    challengeName === AUTH_CHALLENGES.NEW_PASSWORD ||
    challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED
  ) {
    return <NewPassword />;
  }

  return (
    <Switch>
      <Route exact path={ROUTES.authResetPassword} component={ResetPassword} />
      <Route
        exact
        path={ROUTES.authSubmitResetPassword}
        component={SubmitResetPassword}
      />
      <Route exact path={ROUTES.authSignOut} component={Logout} />
      <Route path={ROUTES.auth} component={Login} />
    </Switch>
  );
}

export default Auth;
