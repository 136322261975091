import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import {
  PageWrapper,
  PartnerStrip,
  DiscoverMeLogo,
} from "@omnigenbiodata/react";

import { useStyles } from "./component.styles";
import { PARTNER_LOGOS } from "../../core/constants/content.constant";
import ANIMATIONS from "../../core/constants/animations.constant";

interface MainLayoutProps {
  children: ReactNode;
  title?: string;
}

function MainLayout({ children, title = "" }: MainLayoutProps) {
  const classes = useStyles();
  return (
    <PageWrapper>
      <div className={classes.header}>
        <Link to="/" className={classes.logo}>
          <Hidden xsDown>
            <DiscoverMeLogo sub="sa" size="medium" />
          </Hidden>
          <Hidden smUp>
            <DiscoverMeLogo sub="sa" size="responsive" />
          </Hidden>
        </Link>
      </div>

      {title && (
        <Typography
          className={classes.MainName}
          variant="h2"
          component="h1"
          align="center"
        >
          {title}
        </Typography>
      )}
      <Fade in timeout={ANIMATIONS.duration}>
        <div className={classes.content}>{children}</div>
      </Fade>
      <div className={classes.footer}>
        <PartnerStrip logos={PARTNER_LOGOS} />
      </div>
    </PageWrapper>
  );
}

export default MainLayout;
