export const ageGroupMapper = (data: any) => {
  return Object.values(
    data.reduce((prev: any, row: any) => {
      return {
        ...prev,
        [row.ageCategory]: {
          ...prev[row.ageCategory],
          [row.sex]: row.numEnrolments,
          ageCategory: row.ageCategory,
        },
      };
    }, {})
  )
    .map((row: any) => ({
      ...row,
      Intersex: row.Intersex || 0,
      Male: row.Male || 0,
      Female: row.Female || 0,
    }))
    .sort((a, b) => {
      if (a.ageCategory > b.ageCategory) {
        return 1;
      } else {
        return -1;
      }
    }) as any;
};
