import React, { ReactNode, useState } from "react";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";

export interface ContentCardProps {
  children: ReactNode;
}

function TabWrapper({ children }: ContentCardProps) {
  const [value, setValue] = useState("1");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleTabChange} aria-label="Participant Info">
        <Tab label="Profile" value="1" />
        <Tab label="Enrolment" value="2" />
        <Tab label="Samples" value="3" />
      </TabList>
      {children}
    </TabContext>
  );
}

export default TabWrapper;
