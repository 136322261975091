import React, { ReactNode } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

export interface ContentCardProps {
  children: ReactNode;
  margin?: "normal" | "dense" | "none";
}

function ContentCard({ children, margin = "normal" }: ContentCardProps) {
  return (
    <Box mb={margin === "normal" ? 4 : margin === "dense" ? 2 : 0}>
      <Paper>
        <Box p={4}>{children}</Box>
      </Paper>
    </Box>
  );
}

export default ContentCard;
