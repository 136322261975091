import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chartContainer: {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media print": {
      marginBottom: 30,
    },
  },
  chartTitle: { flexGrow: 1 },
  chartXAxisLabel: { textAnchor: "middle" },
  chartYAxisLabel: { textAnchor: "middle" },
  chartLegend: { paddingTop: 20 },
}));
