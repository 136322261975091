import { createAsyncThunk } from "@reduxjs/toolkit";
import participantApi from "../../core/api/participants/participantApi";
import {
  GetParticipantConsentFormLinkInput,
  GetParticipantConsentFormLinkResponse,
  ParticipantRejection,
} from "../../core/types/participants.types";

export const getParticipantConsentFormLinkThunk = createAsyncThunk<
  GetParticipantConsentFormLinkResponse,
  GetParticipantConsentFormLinkInput,
  ParticipantRejection
>(
  "summary/getParticipantConsentFormLink",
  async (values, { rejectWithValue }) => {
    try {
      const response = await participantApi.getParticipantConsentFormLink(
        values
      );
      return response.data.getParticipantConsentFormLink;
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  }
);
