import React, { useEffect } from "react";
import { Loader, useAuth } from "@omnigenbiodata/react";
import ROUTES from "../../../../core/constants/routes.constant";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { resetUserStore, userProfileSelector } from "../../../../store/user";
import { resetMonitoring } from "../../../../store/monitoring";
import { resetParticipantStore } from "../../../../store/participant";
import { resetSummary } from "../../../../store/summary";
import { Redirect } from "react-router-dom";

function Logout() {
  const { signOut } = useAuth();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(userProfileSelector);

  useEffect(() => {
    if (profile) {
      dispatch(resetUserStore());
      dispatch(resetParticipantStore());
      dispatch(resetMonitoring());
      dispatch(resetSummary());
    }
  }, [dispatch, profile, signOut]);

  if (!profile) {
    return <Redirect to={ROUTES.root} />;
  }
  return <Loader isVisible label="Signing Out" />;
}

export default Logout;
