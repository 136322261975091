import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Authentication from "./Authentication";
import PortalScene from "./Portal";
import SplashScene from "./Splash";

import ROUTES from "../core/constants/routes.constant";
import { AuthRoute } from "@omnigenbiodata/react";
import ConsentScene from "./Consent";

function Scenes() {
  return (
    <Switch>
      <Route path={ROUTES.auth} component={Authentication} />
      <AuthRoute path={ROUTES.portal} component={PortalScene} />
      <AuthRoute path={ROUTES.splash} component={SplashScene} />
      <AuthRoute path={ROUTES.consentTerms} component={ConsentScene} />
      <Redirect to={ROUTES.portal} />
    </Switch>
  );
}

export default Scenes;
