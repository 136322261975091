import { RootState } from "../index";
import { StoreStatus } from "../../core/types/common.types";
import { initialChartState } from ".";

export const chartStateForKeySelector = (
  state: RootState,
  key: string
): any => {
  return state.monitoring.charts
    ? {
        ...state.monitoring.charts[key],
        isBusy:
          state.monitoring.charts && state.monitoring.charts[key]
            ? state.monitoring.charts[key].status === StoreStatus.BUSY
            : false,
      }
    : initialChartState;
};

export const filtersSelector = (state: RootState): any => {
  return state.monitoring.filters;
};
