import { createAsyncThunk } from "@reduxjs/toolkit";
import monitoringApi from "../../core/api/monitoring/monitoringApi";
import {
  ChartInputs,
  MonitoringResponse,
  MonitoringRejectionResponse,
} from "../../core/types/monitoring.types";

// Thunks
export const fetchChartThunk = createAsyncThunk<
  MonitoringResponse,
  ChartInputs,
  MonitoringRejectionResponse
>("monitoring/fetchChart", async (chartInputs, { rejectWithValue }) => {
  try {
    return await monitoringApi.fetchChart(chartInputs);
  } catch (error: any) {
    return rejectWithValue(error.errors);
  }
});
