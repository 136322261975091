import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { BsTelephone, BsPinMap, BsHouse, BsPerson } from "react-icons/bs";
import { useFormik } from "formik";
import { FormInput } from "@omnigenbiodata/react";
import Heading2 from "../../../../../../../../../../../components/text/Heading2";
import ContentCard from "../../../../../../../../../../../components/content/ContentCard";
import {
  TELEPHONE_NUMBER_REGEX,
  POSTAL_CODE_REGEX,
} from "../../../../../../../../../../../core/constants/forms.constant";
import { ParticipantProfile } from "../../../../../../../../../../../core/types/participants.types";

export interface ProfileFormProps {
  profile: ParticipantProfile;
  onSubmit: (values: any) => void;
}

function ProfileForm({ profile, onSubmit }: ProfileFormProps) {
  const { t } = useTranslation("portal");

  const validationSchema = yup.object({
    address: yup.string().test(
      "oneOfRequired",
      t("addressIdentifierRequired", {
        ns: "validation",
      }),
      function () {
        return this.parent.postcode || this.parent.address;
      }
    ),
    landline: yup
      .string()
      .matches(
        TELEPHONE_NUMBER_REGEX,
        t("landlineTelephoneFormat", { ns: "validation" })
      ),
    firstName: yup.string().required(
      t("firstNameRequired", {
        ns: "validation",
      })
    ),
    surname: yup.string().required(
      t("lastNameRequired", {
        ns: "validation",
      })
    ),
    postcode: yup
      .string()
      .test(
        "oneOfRequired",
        t("addressIdentifierRequired", {
          ns: "validation",
        }),
        function () {
          return this.parent.postcode || this.parent.address;
        }
      )
      .matches(POSTAL_CODE_REGEX, t("postalCodeFormat", { ns: "validation" })),
  });

  const formik = useFormik({
    initialValues: {
      id: (profile?.id as string) || "",
      address: (profile?.address as string) || "",
      landline: (profile?.landline as string) || "",
      firstName: (profile?.firstName as string) || "",
      surname: (profile?.surname as string) || "",
      postcode: (profile?.postcode as string) || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });

  return (
    <ContentCard>
      <form onSubmit={formik.handleSubmit}>
        <Heading2 color="textPrimary" icon={BsPerson}>
          {t("participantProfile.title")}
        </Heading2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              error={formik.errors.firstName}
              name="firstName"
              label={t("firstName", { ns: "forms" })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstName}
              value={formik.values.firstName}
              startAdornment={
                <BsPerson fontSize={20} style={{ marginRight: 10 }} />
              }
            />
            <FormInput
              error={formik.errors.surname}
              name="surname"
              label={t("surname", { ns: "forms" })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.surname}
              value={formik.values.surname}
              startAdornment={
                <BsPerson fontSize={20} style={{ marginRight: 10 }} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              error={formik.errors.landline}
              name="landline"
              label={t("landline", { ns: "forms" })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.landline}
              value={formik.values.landline}
              startAdornment={
                <>
                  <BsTelephone fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
            />

            <FormInput
              error={formik.errors.address}
              name="address"
              label={t("address", { ns: "forms" })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.address}
              value={formik.values.address}
              startAdornment={
                <>
                  <BsHouse fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
            />
            <FormInput
              error={formik.errors.postcode}
              name="postcode"
              label={t("postcode", { ns: "forms" })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.postcode}
              value={formik.values.postcode}
              startAdornment={
                <>
                  <BsPinMap fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
            />
          </Grid>
        </Grid>

        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
          >
            {t("participantProfile.button")}
          </Button>
        </Box>
      </form>
    </ContentCard>
  );
}

export default ProfileForm;
