import React from "react";
import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import Box from "@material-ui/core/Box";
import { ButtonRow, Loader } from "@omnigenbiodata/react";
import { Redirect, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Markdown from "react-markdown";
import Typography from "@material-ui/core/Typography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store";
import { HOSPTIAL_NUM_PREFIX } from "../../../../../../../../../../core/constants/forms.constant";
import InnerLayout from "../../../../../../../../../../layout/Inner";
import Heading1 from "../../../../../../../../../../components/text/Heading1";
import ContentCard from "../../../../../../../../../../components/content/ContentCard";
import {
  adminWithdrawParticipantThunk,
  isWithdrawalBusySelector,
  participantProfileSelector,
  withdrawalResultSelector,
  withdrawalValuesSelector,
} from "../../../../../../../../../../store/participant";
import ROUTES, {
  URL_PARAMS,
} from "../../../../../../../../../../core/constants/routes.constant";

function WithdrawalConfirmScene() {
  const { t } = useTranslation("portal");
  let { hospitalNo } = useParams<any>();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(participantProfileSelector);
  const isBusy = useAppSelector(isWithdrawalBusySelector);
  const withdrawalValues = useAppSelector(withdrawalValuesSelector);
  const withdrawalResult = useAppSelector(withdrawalResultSelector);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (profile) {
        dispatch(
          adminWithdrawParticipantThunk({
            participantId: profile.id,
            ...withdrawalValues,
          })
        );
      }
    },
  });

  if (withdrawalResult) {
    return (
      <Redirect
        to={ROUTES.participant.replace(URL_PARAMS.hospitalNo, hospitalNo)}
      />
    );
  }

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        {!isBusy && profile && (
          <>
            <Heading1 icon={GroupIcon}>
              {t("participant.title")} &gt;{" "}
              <span style={{ fontSize: "0.7em" }}>
                {HOSPTIAL_NUM_PREFIX}
                {hospitalNo.toString()}
              </span>
            </Heading1>
            <ContentCard>
              <form onSubmit={formik.handleSubmit}>
                <Box mb={8}>
                  <Typography
                    variant="h4"
                    component="h1"
                    align="center"
                    gutterBottom
                  >
                    {t("withdrawalConfirm.title")}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    align="center"
                    gutterBottom
                  >
                    <strong>{t("withdrawalConfirm.subtitle")}</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="center"
                    gutterBottom
                  >
                    {t("withdrawalConfirm.para1")}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    align="center"
                    gutterBottom
                  >
                    <strong>
                      {profile.firstName} {profile.surname}
                    </strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="center"
                    gutterBottom
                  >
                    {t("withdrawalConfirm.para2")}
                  </Typography>
                  {withdrawalValues?.withdrawalOption && (
                    <Typography variant="body1" component="span" align="center">
                      <Markdown>
                        {t(`${withdrawalValues.withdrawalOption}.label`, {
                          ns: "withdrawals",
                        })}
                      </Markdown>
                    </Typography>
                  )}
                </Box>
                <ButtonRow
                  backLabel={t("withdrawalConfirm.back")}
                  forwardLabel={t("withdrawalConfirm.button")}
                  forwardColor="secondary"
                  buttonSize="small"
                  isDisabled={isBusy}
                />
              </form>
            </ContentCard>
          </>
        )}
      </InnerLayout>
      <Loader label="Busy" isVisible={isBusy} />
    </>
  );
}

export default WithdrawalConfirmScene;
