import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { KeyValues, StoreStatus } from "../../core/types/common.types";
import { fetchChartThunk } from "./thunks";

// Initial State

export interface ChartState {
  errors: any;
  status: any;
  values: KeyValues | null;
  data: any;
}

export interface ChartsState {
  [key: string]: ChartState;
}

export interface MonitoringState extends Record<string, any> {
  charts: ChartsState | null;
  filters: KeyValues | null;
}

export const initialChartState: ChartState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  data: null,
};

export const initialState: MonitoringState = {
  charts: null,
  filters: {
    fromDate: moment().subtract(7, "day").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  },
};

// Slice
export const monitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    resetMonitoringResult: (state: MonitoringState) => ({
      ...state,
      charts: initialState.charts,
    }),
    resetMonitoring: () => ({
      ...initialState,
    }),
    setFilters: (state: MonitoringState, action) => ({
      ...state,
      filters: action.payload,
    }),
  },
  extraReducers: {
    [fetchChartThunk.pending.type]: (state: MonitoringState, action) => {
      state.charts = {
        ...state.charts,
        [action.meta.arg.chartType]: {
          ...(state.charts ? state.charts[action.meta.arg.chartType] : null),
          errors: null,
          status: StoreStatus.BUSY,
          values: action.meta.arg,
        },
      };
    },
    [fetchChartThunk.fulfilled.type]: (state: MonitoringState, action) => {
      state.charts = {
        ...state.charts,
        [action.meta.arg.chartType]: {
          ...(state.charts ? state.charts[action.meta.arg.chartType] : null),
          errors: null,
          status: StoreStatus.IDLE,
          data: action.payload,
        },
      };
    },
    [fetchChartThunk.rejected.type]: (state: MonitoringState, action) => {
      state.charts = {
        ...state.charts,
        [action.meta.arg.chartType]: {
          ...(state.charts ? state.charts[action.meta.arg.chartType] : null),
          errors: action.payload,
          status: StoreStatus.ERROR,
          data: null,
        },
      };
    },
  },
});

// Action Creators
export * from "./thunks";
export * from "./selectors";
export const { resetMonitoringResult, resetMonitoring, setFilters } =
  monitoringSlice.actions;
export default monitoringSlice.reducer;
