import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import Heading2 from "../../../../../../../../../../../components/text/Heading2";
import ROUTES, {
  URL_PARAMS,
} from "../../../../../../../../../../../core/constants/routes.constant";
import { useStyles } from "./component.styles";
import { WithdrawalOption } from "../../../../../../../../../../../core/types/participants.types";

export interface WithdrawalBlockProps {
  hospitalNo: string;
  withdrawalOption: string | null;
}

function WithdrawalBlock({
  hospitalNo,
  withdrawalOption,
}: WithdrawalBlockProps) {
  const classes = useStyles();
  const { t } = useTranslation("portal");
  return (
    <Paper className={classes.paperDanger}>
      <Box p={4}>
        <Heading2 color="textPrimary" icon={MdOutlineCancel}>
          {t("participantWithdrawal.title")}
        </Heading2>

        {withdrawalOption && (
          <>
            <Typography variant="body1" component="p" paragraph>
              {t("participantWithdrawal.previously")}
            </Typography>
            <Typography variant="body1" component="span">
              <Markdown>
                {t(`${withdrawalOption}.label`, { ns: "withdrawals" })}
              </Markdown>
            </Typography>
          </>
        )}
        {withdrawalOption !== WithdrawalOption.COMPLETE && (
          <>
            <Typography variant="body1" component="p" paragraph>
              {t("participantWithdrawal.para1")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              component={Link}
              to={ROUTES.participantWithdrawal.replace(
                URL_PARAMS.hospitalNo,
                hospitalNo
              )}
            >
              {t("participantWithdrawal.button")}
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default WithdrawalBlock;
