import React, { KeyboardEvent, MouseEvent } from "react";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useTranslation } from "react-i18next";
import {
  RiHome2Line,
  RiUser3Line,
  RiHealthBookLine,
  RiLogoutBoxLine,
} from "react-icons/ri";
import ROUTES from "../../../core/constants/routes.constant";
import { useStyles } from "./component.styles";
import { useAuth } from "@omnigenbiodata/react";

interface SideMenuProps {
  isOpen: boolean;
  onToggle: (open: boolean) => (event: MouseEvent | KeyboardEvent) => void;
}

function SideMenu({ isOpen, onToggle }: SideMenuProps) {
  const { t } = useTranslation("portal");
  const { signOut } = useAuth();
  const classes = useStyles();
  return (
    <>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={onToggle(false)}
        data-testid="menu-drawer"
      >
        <Box
          className={classes.drawer}
          role="presentation"
          onClick={onToggle(false)}
          onKeyDown={onToggle(false)}
        >
          <List>
            <ListItem
              data-testid="nav-item"
              button
              key={t("home.navigation.homeLink") as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.root}
            >
              <ListItemIcon>
                <RiHome2Line fontSize="inherit" className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t("home.navigation.homeLink")} />
            </ListItem>
            <ListItem
              data-testid="nav-item"
              button
              key={t("home.navigation.participantLink") as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.participantLookup}
            >
              <ListItemIcon>
                <RiHealthBookLine fontSize="inherit" className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t("home.navigation.participantLink")} />
            </ListItem>
            <ListItem
              data-testid="nav-item"
              button
              key={t("home.navigation.monitoringLink") as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.monitoring}
            >
              <ListItemIcon>
                <RiUser3Line fontSize="inherit" className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t("home.navigation.monitoringLink")} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              data-testid="nav-logout"
              button
              key={t("home.navigation.logoutLink") as string}
              component={"button"}
              onClick={(event: MouseEvent) => {
                signOut();
                event.preventDefault();
              }}
            >
              <ListItemIcon>
                <RiLogoutBoxLine fontSize="inherit" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={t("home.navigation.logoutLink") as string}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default SideMenu;
