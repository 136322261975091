import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import ParticipantDetail from "./scenes/ParticipantDetails";
import ParticipantWithdrawal from "./scenes/ParticipantWithdrawal";
import ROUTES from "../../../../../../core/constants/routes.constant";
import { participantProfileSelector } from "../../../../../../store/participant";
import { useAppSelector } from "../../../../../../store";

function ParticipantResultScene() {
  let { hospitalNo } = useParams<any>();
  const profile = useAppSelector(participantProfileSelector);

  if ((!profile && !hospitalNo) || profile?.hospitalNo !== hospitalNo) {
    return <Redirect to={ROUTES.participantLookup} />;
  }

  return (
    <Switch>
      <Route exact path={ROUTES.participant} component={ParticipantDetail} />
      <Route
        path={ROUTES.participantWithdrawal}
        component={ParticipantWithdrawal}
      />
    </Switch>
  );
}

export default ParticipantResultScene;
