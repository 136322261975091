import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    ".recharts-wrapper, .recharts-surface": {
      "@media print": {
        width: "calc(100%) !important",
      },
    },
  },
  printContainer: {
    "@media print": {
      padding: 40,
    },
  },
  pageBreak: {
    "@media print": {
      pageBreakBefore: "always",
      marginBottom: 30,
    },
  },
}));
