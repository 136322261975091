import React, { useEffect } from "react";
import InnerLayout from "../../../../layout/Inner";
import Box from "@material-ui/core/Box";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import SpeedOutlinedIcon from "@material-ui/icons/SpeedOutlined";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  STUDY_EMAIL,
  STUDY_TEL,
} from "../../../../core/constants/content.constant";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  fetchSummaryThunk,
  isBusySelector,
  resultSelector,
} from "../../../../store/summary";
import { userProfileSelector } from "../../../../store/user";
import { filtersSelector, setFilters } from "../../../../store/monitoring";
import AccordionBlock from "../../../../components/content/AccordionBlock";
import Heading1 from "../../../../components/text/Heading1";
import Heading2 from "../../../../components/text/Heading2";
import ReportLookup from "../../../../components/content/ReportLookup";
import SummaryStrip from "./components/SummaryStrip";
import ROUTES from "../../../../core/constants/routes.constant";

function Home() {
  const { t } = useTranslation("portal");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isSummaryBusy = useAppSelector(isBusySelector);
  const summary = useAppSelector(resultSelector);
  const profile = useAppSelector(userProfileSelector);
  const filters = useAppSelector(filtersSelector);

  const replacements = {
    studyEmail: STUDY_EMAIL,
    studyTelephone: STUDY_TEL,
  };

  useEffect(() => {
    dispatch(fetchSummaryThunk());
  }, [dispatch]);

  const handleFilter = (filters: any) => {
    dispatch(setFilters(filters));
    history.push(ROUTES.monitoring);
  };

  return (
    <InnerLayout title="Site" title2="Portal">
      {profile && (
        <Heading1>
          {t("home.welcome")}, <strong>{profile.firstName}</strong>
        </Heading1>
      )}

      <Box mb={4}>
        <Heading2 icon={SpeedOutlinedIcon}>{t("home.overviewTitle")}</Heading2>
        <SummaryStrip
          isBusy={isSummaryBusy}
          participantTotal={summary?.totalEnrolments}
          departmentTotal={summary?.distinctDepts}
          withdrawalTotal={summary?.totalWithdrawals}
        />
      </Box>

      <Box mb={4}>
        <Heading2 icon={BarChartOutlinedIcon}>
          {t("home.monitoringTitle")}
        </Heading2>
        <ReportLookup onFilter={handleFilter} initialValues={filters} />
      </Box>

      <Box mb={4}>
        <Heading2 icon={DescriptionOutlinedIcon}>
          {t("home.docsTitle")}
        </Heading2>
        <AccordionBlock
          id="doc2"
          title={t("home.helpTitle")}
          content={t("home.helpText", replacements)}
        />
        <AccordionBlock
          id="doc1"
          title={t("home.helpTitle2")}
          content={t("home.helpText2", replacements)}
        />
      </Box>

      <Box mb={4}>
        <Heading2 icon={LiveHelpOutlinedIcon}>{t("home.faqsTitle")}</Heading2>
        <AccordionBlock
          id="faq1"
          title={t("home.helpTitle")}
          content={t("home.helpText", replacements)}
        />

        <AccordionBlock
          id="faq2"
          title={t("home.helpTitle2")}
          content={t("home.helpText2", replacements)}
        />
      </Box>
    </InnerLayout>
  );
}

export default Home;
