import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import { PartnerStrip, useAuth } from "@omnigenbiodata/react";

import ANIMATIONS from "../../core/constants/animations.constant";
import { PARTNER_LOGOS } from "../../core/constants/content.constant";
import SideMenu from "../../components/navigation/SideMenu";

import { useStyles } from "./component.styles";
import ROUTES from "../../core/constants/routes.constant";
import PortalPage from "../../components/layout/PortalPage";
import { useAppSelector } from "../../store";
import { userProfileSelector } from "../../store/user";

interface InnerLayoutProps {
  children: ReactNode;
  title?: string;
  title2?: string;
}

function InnerLayout({ children, title = "", title2 = "" }: InnerLayoutProps) {
  const classes = useStyles();
  const [isMenuOpenFlag, setIsMenuOpenFlag] = useState(false);
  const { signOut } = useAuth();
  const profile = useAppSelector(userProfileSelector);

  const handleToggleMenu = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsMenuOpenFlag(open);
  };

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Toolbar>
          <IconButton
            onClick={handleToggleMenu(true)}
            edge="start"
            className={classes.appBarHamburger}
            data-testid="menu-btn"
          >
            <MenuIcon />
          </IconButton>
          <Link to={ROUTES.root}>
            <img
              src="/images/logo-small.png"
              alt="Discover Me Logo"
              className={classes.imgLogo}
            />
          </Link>
          <span className={classes.appBarDivider}></span>
          <Typography variant="h6" className={classes.appBarTitle}>
            <strong>{title}</strong>
            {title2}
          </Typography>

          <Hidden xsDown>
            {profile && (
              <>
                <Typography variant="body1" className={classes.appBarUser}>
                  {profile.firstName} {profile.surname}
                </Typography>{" "}
                <IconButton
                  edge="end"
                  className={classes.appBarUserIconBtn}
                  data-testid="account-btn"
                >
                  <PersonIcon />
                </IconButton>
              </>
            )}

            <Button
              color="inherit"
              onClick={() => {
                signOut();
              }}
            >
              Sign Out
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      <SideMenu isOpen={isMenuOpenFlag} onToggle={handleToggleMenu} />
      <div className={classes.layoutWrapper}>
        <div className={classes.layoutContent}>
          <Container>
            <Fade in timeout={ANIMATIONS.duration}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <PortalPage>{children}</PortalPage>
                </Grid>
              </Grid>
            </Fade>
          </Container>
        </div>
        <div className={classes.layoutFooter}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs>
                <PartnerStrip logos={PARTNER_LOGOS} />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default InnerLayout;
