import { RootState } from "../index";
import { StoreStatus, KeyValues } from "../../core/types/common.types";

export const isBusySelector = (state: RootState): boolean =>
  state.consent.status && state.consent.status === StoreStatus.BUSY;

export const hasErrorSelector = (state: RootState): boolean =>
  state.consent.errors && state.consent.errors.length > 0 ? true : false;

export const hasSuccessSelector = (state: RootState): boolean =>
  state.consent.result ? true : false;

export const resultSelector = (state: RootState): any => state.consent.result;

export const valueSelector = (state: RootState): KeyValues =>
  state.consent.values || {};
