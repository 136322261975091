import React, { useState, ReactNode } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import { Loader } from "@omnigenbiodata/react";
import PieChartIcon from "@material-ui/icons/PieChart";
import TocOutlinedIcon from "@material-ui/icons/TocOutlined";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./component.styles";

export enum DataView {
  DONUT = "donut",
  PIE = "pie",
  CHART = "chart",
  TABLE = "table",
}

export interface ChartContainerProps {
  children: (dataView: DataView) => ReactNode;
  title: string;
  subtitle?: string;
  isBusy?: boolean;
  loadingText?: string;
  dataViews?: DataView[];
}

const dataViewIcons: any = {
  donut: <DonutLargeIcon />,
  pie: <PieChartIcon />,
  chart: <ShowChartOutlinedIcon />,
  table: <TocOutlinedIcon />,
};

function ChartContainer({
  children,
  dataViews = [DataView.CHART, DataView.TABLE],
  isBusy = false,
  loadingText = "Loading",
  subtitle,
  title,
}: ChartContainerProps) {
  const [dataView, setDataView] = useState<DataView>(dataViews[0]);
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.chartWrapper}>
      <Box p={2}>
        <Toolbar className={classes.chartToolbar} variant="dense">
          <span className={classes.chartTitle}>
            <Typography
              variant="h5"
              component="h3"
              align="left"
              color="textSecondary"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              component="h4"
              align="left"
              color="primary"
            >
              {subtitle}
            </Typography>
          </span>
          {dataViews.map((viewKey, index) => (
            <IconButton
              key={`${title}${index}`}
              edge="end"
              data-testid={`${viewKey}-btn`}
              onClick={() => {
                setDataView(viewKey);
              }}
              color={dataView === viewKey ? "primary" : "default"}
            >
              {dataViewIcons[viewKey]}
            </IconButton>
          ))}
        </Toolbar>
        <Loader label={loadingText} isVisible={isBusy} isInline />
        {!isBusy && children(dataView)}
      </Box>
    </Paper>
  );
}

export default ChartContainer;
